<template>
    <Page
        title="Action Flow"
        icon="mdi-file-tree"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
        class-extra="mx-auto"
    >
        <v-row>
            <v-col cols="5">
                <date-field
                    @dateValue="() => loadData()"
                    @fromDate="fromDate = $event"
                    @toDate="toDate = $event"
                    range
                    today
                />
                <text-field clearable placeholder="root id" v-model="searchRootId" />
            </v-col>
            <v-col cols="2">
                <select-field
                    v-model="numResults"
                    label="Number of Results"
                    :items="numResultsOptions"
                />
                <v-switch label="Errors " color="red" v-model="isShowErrorsOnly"></v-switch>
            </v-col>
            <v-col cols="2">
                <btn @click="loadData()" small>Search</btn>
            </v-col>
        </v-row>

        <v-row style="min-width: 800px;">
            <v-container class="flex">
                <v-row class="">
                    <v-col class="pointer" style="">
                        <action-flow
                            v-if="actionFlowData.length"
                            :action-flow-data="actionFlowData"
                            open-all
                            :loadAll="loadData"
                            showRetry
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </Page>
</template>
<script>
import dateUtil from '@/../common/utils/date';
import * as queryString from 'query-string';

import ActionFlow from '@/components/common/ActionFlow';

import api from '@/api';

export default {
    components: { ActionFlow },
    data: () => ({
        dateRange: [dateUtil.getTodayString(), dateUtil.getTodayString()],
        numResultsOptions: ['1', '5', '20', '100', '1000'],
        numResults: '5',
        fromDate: null,
        toDate: null,
        menu: false,
        isShowErrorsOnly: false,
        actionFlowData: [],
        loadingMessage: '',
        isShowLoading: false,
        errorMessage: '',
        isShowError: false,
        showDialog: false,
        testDate: '2020-06',
        searchRootId: undefined,
    }),
    methods: {
        async loadData(id) {
            try {
                this.loadingMessage = `Loading Action Flow Data`;
                this.isShowLoading = true;
                const url = this.searchRootId
                    ? `actionflow/root/${this.searchRootId}`
                    : 'actionflow';
                const params = queryString.stringify({
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    numResults: this.numResults,
                    errorsOnly: this.isShowErrorsOnly,
                });
                this.actionFlowData = [];
                const result = await api.get(this.$store, `${url}?${params}`);
                this.actionFlowData = result.data || [];
                this.loadingMessage = '';
                this.isShowLoading = false;
            } catch (e) {
                console.log('error loading action flows', e.message);
            }
        },
        closeDialog() {
            this.selectedItem = null;
            this.active = [];
            this.showDialog = false;
        },
        getStatusColor(actionStatus) {
            if (actionStatus === 'success') return 'green';
            if (actionStatus === 'error') return 'red';
            return 'orange';
        },
        showTestDate() {
            console.log('showTestDate', this.testDate);
        },
    },
    async created() {
        console.log('created');
    },
};
</script>
<style>
.layout {
    word-break: break-all;
}
.pointer {
    cursor: pointer;
}
.sticky {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
}
.scrollable {
    overflow-y: auto;
}
code {
    width: 600px;
}
</style>
