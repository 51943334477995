<template>
    <v-container>
        <!--        <pre text-left> {{ JSON.stringify(this.actionFlowData, null, 2) }} </pre>-->
        <v-treeview
            :open-all="openAll"
            v-on:update:active="clickRow"
            :active.sync="active"
            :items="items"
            dense
            activatable
        >
            <template slot="prepend" slot-scope="{ item }">
                <v-col style="max-width: 700px" class="py-0">
                    <p :class="item.parentId ? 'grey--text text--darken-1' : ''">
                        {{ item.description }}
                    </p>
                    <p class="yellow--text" v-if="!item.parentId && item.actionNote">- {{ item.actionNote }}</p>
                    <p class="error--text" v-if="!item.parentId && item.actionError">- {{ item.actionError }}</p>

                    <!--                    <pre>{{ JSON.stringify(item, null, 2) }}</pre>-->
                </v-col>
                <v-col v-if="runLabel && !item.parentId" class="green--text white" align="center">
                    <v-progress-circular v-if="!!runLabel" indeterminate :size="13" :width="2" class="mr-3" />

                    {{ runLabel }}
                </v-col>
            </template>
            <template slot="append" slot-scope="{ item }">
                <btn @click="clickRetry(item)" v-if="!runLabel && showRetry && !item.parentId" label="Retry" small />
                <v-chip :color="getStatusColor(item.actionStatus)" class="text-capitalize mx-5" outlined>
                    {{ item.actionStatus || 'pending' }}
                </v-chip>
            </template>
        </v-treeview>
        <v-dialog width="800" v-model="showDialog" scrollable v-on:click:outside="closeDialog">
            <v-card width="600">
                <v-card-title class="">
                    {{ selectedItemData.actionName }} -
                    {{ selectedItemData.actionStatus }}
                    <btn label="Copy Root Id" :clipboardValue="selectedItemData.rootId" preset="clipboard" />
                </v-card-title>
                <v-card-text>
                    <v-container v-if="selectedItemData && selectedItemData.actionInput">
                        <v-row>
                            Input
                            <btn
                                label="Copy Action Input"
                                :clipboardValue="JSON.stringify(selectedItemData.actionInput, null, 2)"
                                preset="clipboard"
                            />
                        </v-row>
                        <v-row>
                            <vue-json-tree :data="selectedItemData.actionInput" />
                        </v-row>
                        <v-row>
                            Result
                            <btn
                                label="Copy Action Result"
                                :clipboardValue="JSON.stringify(selectedItemData.actionResult, null, 2)"
                                preset="clipboard"
                            />
                        </v-row>
                        <v-row>
                            <vue-json-tree :data="selectedItemData.actionResult" />
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <btn color="primary" text @click="closeDialog" small> Close </btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import dateUtil from '@/../common/utils/date';

import api from '@/api';
import VueJsonTree from 'vue-json-tree';

export default {
    name: 'ActionFlow',
    components: { VueJsonTree },
    props: { showRetry: Boolean, actionFlowData: Array, openAll: Boolean, loadAll: Function },
    data: () => ({
        active: [],
        items: [],
        selectedItem: null,
        selectedItemData: {},
        loadingMessage: '',
        isShowLoading: false,
        errorMessage: '',
        isShowError: false,
        showDialog: false,
        showInput: false,
        runLabel: '',
    }),
    methods: {
        async clickRetry(item, resumeRootId) {
            const returnData = await api.post(this.$store, `actionflow/retry/${item.id}`, {
                resumeRootId,
            });
            if (returnData.data && returnData.data.breakDescription) {
                this.runLabel = returnData.data.breakDescription;
                return await this.clickRetry(item, returnData.data.rootId);
            }
            this.runLabel = '';
            this.loadAll();
        },
        clickRow(item) {
            this.selectedItem = item.length ? item[0] : null;
            if (this.selectedItem) this.loadData(this.selectedItem);
        },
        getTreeChildren(data, parentId, sortChildren) {
            return data
                .filter((oneRecord) => oneRecord.parentId === parentId)
                .map((oneRecord) => {
                    const returnData = {
                        ...oneRecord,
                        description: `${dateUtil.format(new Date(oneRecord.createdAt), 'HH:mm:ss')} - ${
                            oneRecord.actionName
                        }`,
                    };
                    if (data.find((childRecord) => childRecord.parentId === oneRecord.id)) {
                        returnData['children'] = this.getTreeChildren(data, oneRecord.id, oneRecord.actionSortChildren);
                    }
                    return returnData;
                })
                .sort((a, b) => (sortChildren ? (a.description > b.description ? 1 : -1) : 1));
        },
        convertDataToTree(data) {
            return data
                .filter((oneRecord) => !oneRecord.parentId)
                .sort((e1, e2) => (e1.createdAt < e2.createdAt ? 1 : -1))
                .map((oneRecord) => {
                    const returnData = {
                        ...oneRecord,
                        description: `${dateUtil.format(new Date(oneRecord.createdAt), 'HH:mm:ss - d MMM yy')} - ${
                            oneRecord.actionType
                        } ${oneRecord.actionName}`,
                        actionDate: dateUtil.format(new Date(oneRecord.createdAt), 'HH:mm:ss - d MMM yy'),
                        children: this.getTreeChildren(data, oneRecord.id, oneRecord.actionSortChildren),
                    };

                    return returnData;
                });
        },
        async loadData(id) {
            try {
                const returnData = await api.get(this.$store, `actionflow/${id}`);
                this.selectedItemData = returnData.data;
                this.showDialog = true;
            } catch (e) {
                console.log('error loading action flows', e.message);
            }
        },

        closeDialog() {
            this.selectedItem = null;
            this.active = [];
            this.showDialog = false;
        },
        getStatusColor(actionStatus) {
            if (actionStatus === 'success') return 'green';
            if (actionStatus === 'error') return 'red';
            return 'orange';
        },
    },
    async created() {
        this.items = this.convertDataToTree(this.actionFlowData);
    },
};
</script>
<style>
.pre {
    white-space: pre-wrap;
}
.layout {
    word-break: break-all;
}
.pointer {
    cursor: pointer;
}
.sticky {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
}
.scrollable {
    overflow-y: auto;
}
code {
    width: 600px;
}

p {
    display: inline;
    white-space: normal;
    word-break: break-word;
}
</style>
